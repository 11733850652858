import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

export type DatasourceSource =
  | 'sharepoint-online'
  | 'webcrawler'
  | 'public-internet'
  | 'unknown'
  | 'knowledge-base'
  | 'brave-search-api';

export type DatasourceProvider = 'kendra' | 'web' | 'bedrock' | 'unknown';

export class DatasourceModel extends BaseModel {
  name: string;
  source: DatasourceSource;
  provider: DatasourceProvider;
  description: string;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof DatasourceModel, unknown>;

    this.name = (data.name as string) || '';
    this.source = (data.source as DatasourceSource) || 'unknown';
    this.provider = (data.provider as DatasourceProvider) || 'unknown';
    this.description = (data.description as string) || '';
  }

  get isPublicInternet(): boolean {
    return this.provider === 'web' || ['brave-search-api', 'public-internet'].includes(this.source);
  }
}
