import { Box, Fade, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import copy from 'copy-to-clipboard';
import Text from '@/components/text';
import DoneIcon from '@/components/icon/done-icon';
import { createUseStyles } from 'react-jss';
import { ThreadContext, ThreadMessage as ThreadMessageType } from '@/lib/services/thread.service';
import ShortcutForm from './shortcut-form';
import MessageContent from './message-content';
import Icon from '@/components/icon';
import Tooltip from '@/components/tooltip';
import { ShortcutMessage } from '@/lib/models/shortcut.model';
import FlatButton from '@/components/flat-button';
import ThreadFile from './thread-file';
import MessageContextMenu from './message-context-menu';
import MessageDatasourceMenu from './message-datasource-menu';

interface Props {
  BadgeEl: ReactNode;
  roleTitle: string;
  message: ThreadMessageType;
  threadContext?: ThreadContext;
  onEdit?: (message: ThreadMessageType) => void;
  onRegenerate?: (message: ThreadMessageType) => void;
  onSendShortcut?: (shortcut: ShortcutMessage) => void;
  onRemove?: () => void;
  onChangeShortcut?: (shortcut: ShortcutMessage) => void;
  hideToolbar?: boolean;
  threadLocked?: boolean;
}

const useStyles = createUseStyles({
  threadMessageContent: {
    overflow: 'hidden',
    flexGrow: 1,
    gap: 16,
  },
  badge: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: 50,
    minWidth: 50,
    overflow: 'hidden',
    '& img': {
      width: 40,
      height: 40,
    },
  },
  toolbar: {
    '& svg': {
      opacity: 0.7,
      '&:hover, &.done': {
        opacity: 1,
      },
    },
  },
});

const ThreadMessage: FC<Props> = ({
  BadgeEl,
  roleTitle,
  message,
  threadContext,
  onRegenerate,
  onSendShortcut,
  onChangeShortcut,
  onRemove,
  onEdit,
  hideToolbar = false,
  threadLocked = false,
}) => {
  const styles = useStyles();

  const { shortcut, role, content, onDeck, context, citations, datasourceIds, agentId } = message;

  const isUser = role === 'user';
  const hasContent = !!content;
  const hasShortcut = !!shortcut;
  const isFile = context?.type === 'file';
  const canCopy = !shortcut && hasContent;
  const canRegenerate = !isUser && hasContent && !threadLocked;
  const canEdit = isUser && !isFile && !hasShortcut && !threadLocked;
  const hasContext = threadContext && !!citations?.length;
  const hasDatasources = isUser && !!datasourceIds?.length;
  const showDatasources = hasDatasources && !agentId;

  const handleCopyMessage = () => {
    if (!canCopy) {
      return;
    }
    copy(content);
  };

  const handleRegenerate = () => {
    onRegenerate?.(message);
  };

  const handleEdit = () => {
    onEdit?.(message);
  };

  const handleSendShortcut = (shortcut: ShortcutMessage) => {
    onSendShortcut?.(shortcut);
  };

  const handleRemoveShortcut = () => {
    onRemove?.();
  };

  const handleChangeShortcut = (shortcut: ShortcutMessage) => {
    onChangeShortcut?.(shortcut);
  };

  let ContentEl: ReactNode = null;

  if (hasShortcut) {
    ContentEl = (
      <ShortcutForm
        shortcut={shortcut}
        onSend={handleSendShortcut}
        messageSent={!onDeck}
        onChange={handleChangeShortcut}
      />
    );

    if (onDeck) {
      ContentEl = (
        <Fade in={true} timeout={90}>
          <Box>{ContentEl}</Box>
        </Fade>
      );
    }
  } else if (isFile) {
    ContentEl = !context ? (
      <Text italic>&lt;file not found&gt;</Text>
    ) : (
      <ThreadFile file={context} />
    );
  } else {
    ContentEl = <MessageContent message={message} />;
  }

  const hasTitle = !!roleTitle;
  const hasToolbar = canCopy || canRegenerate || canEdit || hasContext;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap={2}
      maxWidth="100%"
      width="100%"
    >
      <Box className={styles.badge}>{BadgeEl}</Box>
      <Stack className={styles.threadMessageContent}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Box>{hasTitle && <Text bold>{roleTitle}</Text>}</Box>
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            {hasShortcut && onDeck && (
              <FlatButton
                label="Cancel Shortcut"
                size="small"
                onClick={handleRemoveShortcut}
                color="error"
                icon="delete"
              />
            )}
          </Box>
        </Stack>
        <Box>
          {ContentEl}
          {!hideToolbar && hasToolbar && (
            <Stack
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
              gap={0.5}
              pt={1}
              className={styles.toolbar}
            >
              {canCopy && (
                <Tooltip title="Copy" placement="bottom">
                  <DoneIcon size="small" name="copy" onClick={handleCopyMessage} />
                </Tooltip>
              )}
              {canEdit && (
                <Tooltip title="Edit" placement="bottom">
                  <Icon size="small" name="edit" onClick={handleEdit} />
                </Tooltip>
              )}
              {canRegenerate && (
                <Tooltip title="Regenerate" placement="bottom">
                  <Icon name="refresh" size="small" onClick={handleRegenerate} />
                </Tooltip>
              )}
              {hasContext && (
                <MessageContextMenu threadContext={threadContext} citations={citations} />
              )}
              {showDatasources && <MessageDatasourceMenu datasourceIds={datasourceIds} />}
            </Stack>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ThreadMessage;
