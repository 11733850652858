import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

export class PrivateModelModel extends BaseModel {
  name: string;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PrivateModelModel, unknown>;

    this.name = (data.name as string) || '';
  }
}
