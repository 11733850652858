import { ReactNode, FC, useState, useEffect, useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { LoadState, getUUID, isUUID } from '@/lib/helpers';
import { ShortcutMessage, ShortcutModel } from '@/lib/models/shortcut.model';
import useApp from '@/hooks/use-app.hook';
import {
  getThreadById,
  makeDeckMessage,
  Thread,
  ThreadContext as ThreadContextMap,
  ThreadMessage,
} from '@/lib/services/thread.service';
import { MessageStatus, ThreadContext, ThreadError } from './helpers';
import useThreadToast from '@/hooks/use-thread-toast.hook';
import {
  StreamControlSignal,
  StreamMessage,
  stopStreamClient,
  streamUserMessage,
} from '@/lib/services/thread-stream.service';
import useUserSettings from '@/hooks/use-user-settings.hook';

interface Props {
  children: ReactNode;
}

const getUrlThreadId = (): string => {
  const threadId = String(window.location.pathname).split('/').pop() || '';
  return isUUID(threadId) ? threadId : '';
};

export const ThreadProvider: FC<Props> = ({ children }) => {
  const [thread, setThread] = useState<Thread>({
    id: getUUID(),
    messages: [],
    context: new Map(),
    datasourceIds: [],
    privateModelId: '',
    agentId: '',
  });
  const [loadState, setLoadState] = useState<LoadState>('unloaded');
  const [threadLoadState, setThreadLoadState] = useState<LoadState>('unloaded');
  const [messageStatus, setMessageStatus] = useState<MessageStatus>('open');
  const [controlSignal, setControlSignal] = useState<StreamControlSignal | null>(null);
  const [working, setWorking] = useState(false);
  const [streamContent, setStreamContent] = useState('');

  const { signedIn } = useApp();
  const { toast } = useThreadToast();
  const { datasources } = useUserSettings();

  const hasOnDeckShortcut = useMemo(() => {
    return thread.messages.some(({ onDeck, shortcut }) => onDeck && !!shortcut);
  }, [thread]);

  const getDefaultDatasourceIds = useCallback(() => {
    return Array.from(datasources.values())
      .filter(({ enabled }) => enabled)
      .map(({ model }) => model.id);
  }, [datasources]);

  const loadThread = useCallback(
    async (threadId?: string, agentId?: string): Promise<boolean> => {
      if (threadLoadState === 'loading') {
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.SendingSwitchThread);
        return false;
      }

      if (hasOnDeckShortcut) {
        toast(ThreadError.WorkingSwitchThread);
        return false;
      }

      let thread: Thread | null = null;

      setThreadLoadState('loading');

      if (threadId) {
        thread = await getThreadById(threadId);
      } else {
        thread = {
          id: getUUID(),
          messages: [],
          context: new Map(),
          datasourceIds: [],
          privateModelId: '',
          agentId: agentId || '',
        };
      }

      if (!thread) {
        toast(ThreadError.NoDisassemble, 'error');
        return false;
      }

      setThread(thread);
      setThreadLoadState('loaded');

      return true;
    },
    [threadLoadState, messageStatus, hasOnDeckShortcut, toast]
  );

  const addMessage = useCallback((message: ThreadMessage) => {
    setThread((old) => {
      const updatedMessages = cloneDeep(old.messages);
      updatedMessages.push(message);
      return {
        ...old,
        messages: updatedMessages,
      };
    });
  }, []);

  const streamMessage = useCallback(
    (message: ThreadMessage) => {
      const onMessage = ({
        error,
        content,
        isComplete,
        threadId: streamThreadId,
        threadMessage: streamThreadMessage,
        locked = false,
      }: StreamMessage) => {
        if (streamThreadId !== thread.id) {
          // @todo abort the stream, yikes
          setMessageStatus('open');
          return;
        }

        if (error) {
          // no need to abort the stream, thread service already took care of that
          toast(ThreadError.NoDisassemble, 'error');
          setMessageStatus('open');
          return;
        }

        setMessageStatus('stream');
        setStreamContent(content);

        if (isComplete) {
          setMessageStatus('open');

          if (!streamThreadMessage) {
            toast(ThreadError.NoDisassemble, 'error');
            return;
          }

          setThread((old) => {
            const updated = cloneDeep(old.messages);
            updated.push(streamThreadMessage);

            // mark the user message as no longer being on-deck
            const userMessageIndex = updated.findIndex(({ id }) => id === message.id);
            if (userMessageIndex > -1) {
              updated[userMessageIndex].onDeck = false;
            }

            return {
              ...old,
              messages: updated,
              locked,
            };
          });

          setStreamContent('');
        }
      };

      const onContext = (threadContext: ThreadContextMap) => {
        setThread((old) => {
          return {
            ...old,
            context: threadContext,
          };
        });
      };

      const onControl = (controlSignal: StreamControlSignal | null) => {
        setControlSignal(controlSignal || null);
      };

      message.agentId = thread.agentId;
      message.privateModelId = thread.privateModelId;
      // @todo in the future, datasourceIds will be a comp of thread and user settings
      message.datasourceIds = getDefaultDatasourceIds();

      streamUserMessage(message, onMessage, onContext, onControl);
    },
    [thread.id, thread.privateModelId, thread.agentId, toast, getDefaultDatasourceIds]
  );

  const closeStream = useCallback(() => {
    // close the sse client
    stopStreamClient();
    // @todo if we set the status back to open, the streamed content will disappear, but we might not have enough to finish the message
    setMessageStatus('open');
  }, []);

  const loadShortcut = useCallback(
    (shortcut: ShortcutModel) => {
      if (!thread.id) {
        toast(ThreadError.NotFoundThread, 'error');
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.SendingLoadShortcut);
        return false;
      }

      if (thread.locked) {
        return false;
      }

      // If there is already a shortcut in the thread, but the user has not yet filled out anything, simply replace it with this one being loaded
      const existIndex = thread.messages.findIndex(({ onDeck, shortcut }) => onDeck && shortcut);
      let removeIndex = -1;

      if (existIndex > -1) {
        // user is already working on an on-deck shortcut, don't replace it
        if (working) {
          toast(ThreadError.WorkingLoadShortcut);
          return false;
        }
        removeIndex = existIndex;
      }

      const deckMessage = makeDeckMessage(thread.id, 'user', '', shortcut.message);

      setThread((old) => {
        const updated = cloneDeep(old);
        if (removeIndex > -1) {
          updated.messages.splice(removeIndex, 1);
        }

        updated.messages.push(deckMessage);

        return updated;
      });

      return true;
    },
    [thread, working, messageStatus, toast]
  );

  const removeDeckMessage = useCallback((messageId: string) => {
    setThread((old) => {
      const messages = cloneDeep(old.messages);
      const messageIndex = messages.findIndex(({ id }) => id === messageId);

      if (messageIndex > -1) {
        messages.splice(messageIndex, 1);
      }

      return {
        ...old,
        messages,
      };
    });

    return true;
  }, []);

  const handleSetPrivateModelId = useCallback((privateModelId: string) => {
    setThread((old) => ({
      ...old,
      privateModelId,
    }));
  }, []);

  const handleSetAgentId = useCallback((agentId: string) => {
    setThread((old) => ({
      ...old,
      agentId,
    }));
  }, []);

  const sendDeckMessage = useCallback(
    (message: ThreadMessage) => {
      if (!thread.id) {
        toast(ThreadError.NotFoundThread, 'error');
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.Sending);
        return false;
      }

      const updatedThread = cloneDeep(thread);
      const messageIndex = updatedThread.messages.findIndex(({ id }) => id === message.id);
      if (messageIndex === -1) {
        toast(ThreadError.NotFoundMessage, 'error');
        return false;
      }

      if (thread.locked) {
        return false;
      }

      setMessageStatus('send');

      const { shortcut } = message;

      // mark the on-deck message as having been sent
      setThread((old) => {
        const updatedMessages = cloneDeep(old.messages);
        updatedMessages[messageIndex].onDeck = false;
        updatedMessages[messageIndex].shortcut = shortcut;
        updatedMessages[messageIndex].createdAt = new Date();

        return {
          ...old,
          messages: updatedMessages,
        };
      });

      streamMessage(message);

      return true;
    },
    [thread, messageStatus, toast, streamMessage]
  );

  /*
   * Given text content that the user enters via MessageControl, create a new thread message, send
   * the message off for inference, then create a new thread message with the response.
   */
  const sendMessage = useCallback(
    (content: string, shortcut?: ShortcutMessage) => {
      if (!thread.id) {
        toast(ThreadError.NotFoundThread, 'error');
        return false;
      }

      if (messageStatus !== 'open') {
        toast(ThreadError.Sending);
        return false;
      }

      if (hasOnDeckShortcut) {
        toast(ThreadError.Working);
        return false;
      }

      if (thread.locked) {
        return false;
      }

      setMessageStatus('send');

      const deckMessage = makeDeckMessage(thread.id, 'user', content, shortcut);

      // because we are sending the message right away, lock the shortcut form by marking it as no longer on-deck
      if (shortcut) {
        deckMessage.onDeck = false;
      }

      addMessage(deckMessage);

      // using sendDeckMessage here would create consistency, but how to work with the recently updated thread?

      streamMessage(deckMessage);

      return true;
    },
    [messageStatus, hasOnDeckShortcut, thread, toast, addMessage, streamMessage]
  );

  // run once on boot
  useEffect(() => {
    if (!signedIn) {
      return;
    }
    if (loadState !== 'unloaded') {
      return;
    }
    setLoadState('loading');
  }, [signedIn, loadState]);

  // load the users thread, their active thread, and the messages for the active thread
  useEffect(() => {
    if (loadState !== 'loading') {
      return;
    }

    const threadId = getUrlThreadId();

    if (!threadId) {
      setLoadState('loaded');
      return;
    }

    getThreadById(threadId).then((activeThread) => {
      if (activeThread) {
        setThread(activeThread);
        setThreadLoadState('loaded');
      } else {
        // @todo error toast this, thread was not found
        history.pushState({}, '', '/home');
      }

      setLoadState('loaded');
    });
  }, [loadState]);

  // ensure the loaded thread is synced with the /home/<thread id> URL
  useEffect(() => {
    if (loadState !== 'loaded') {
      return;
    }

    if (!String(window.location.pathname).startsWith('/home')) {
      return;
    }

    const { id } = thread;

    const threadId = getUrlThreadId();

    if (threadId !== id) {
      history.pushState({}, '', `/home/${id}`);
    }
  }, [thread, loadState]);

  // ensure that the working flag is turned off if there is no on-deck shortcut
  useEffect(() => {
    const onDeckShortcut = thread.messages.some(({ onDeck, shortcut }) => onDeck && !!shortcut);
    if (!onDeckShortcut) {
      setWorking(false);
    }
  }, [thread.messages]);

  return (
    <ThreadContext.Provider
      value={{
        loadState: threadLoadState,
        thread,
        sendMessage,
        controlSignal,
        messageStatus,
        working,
        streamContent,
        setWorking,
        loadShortcut,
        loadThread,
        addMessage,
        sendDeckMessage,
        removeDeckMessage,
        closeStream,
        setPrivateModelId: handleSetPrivateModelId,
        setAgentId: handleSetAgentId,
      }}
    >
      {children}
    </ThreadContext.Provider>
  );
};
