import { md5 } from 'hash-wasm';
import { get as getLocal, set as setLocal, remove as removeLocal } from '../local-storage';
import { OrgBrandingModel } from '../models/org-branding.model';
import { JsonObject } from '../helpers';
import { getCookie, setCookie } from '../cookies';
import { get } from './sp-api.service';
import { AuthProvider } from './auth.service';
import { PrivateModelModel } from '../models/private-model.model';

const ORG_BRANDING_CACHE_KEY = 'portal-org-branding';

export type PortalWelcome = {
  title: string;
  message: string;
  learnMoreLink: string;
};

export type PrivateModelSettings = {
  defaultPrivateModelId: string;
  availablePrivateModels: PrivateModelModel[];
};

export const getOrgBranding = async (): Promise<OrgBrandingModel | null> => {
  const response = await get(`/orgs/${AuthProvider.orgId}`, { fields: 'name,branding' });

  if (!response) {
    return null;
  }

  const { branding, name } = response as { branding: JsonObject; name: string };

  return new OrgBrandingModel({ ...branding, orgName: name });
};

const getWelcomeMessageKey = async (portalWelcome: PortalWelcome) => {
  const { title, message, learnMoreLink } = portalWelcome;
  return md5([title, message, learnMoreLink].join('|')).then((result) => `pwm-${result}`);
};

// Does the user need to see the welcome message?
export const getNeedsWelcomeMessage = async (portalWelcome: PortalWelcome): Promise<boolean> => {
  if (!portalWelcome.message) {
    return false;
  }
  const key = await getWelcomeMessageKey(portalWelcome);
  const welcomeMessageCookie = getCookie(key);
  return !welcomeMessageCookie;
};

export const setNeedsWelcomeMessage = async (portalWelcome: PortalWelcome) => {
  return getWelcomeMessageKey(portalWelcome).then((key) => {
    setCookie(key, '1');
  });
};

export const getCachedOrgBranding = (): OrgBrandingModel | null => {
  const brandingJson = getLocal(ORG_BRANDING_CACHE_KEY, true) as JsonObject;

  if (!brandingJson) {
    return null;
  }

  return new OrgBrandingModel(brandingJson);
};

export const setCachedOrgBranding = (orgBranding: OrgBrandingModel) => {
  setLocal(ORG_BRANDING_CACHE_KEY, orgBranding._json);
};

export const deleteCachedOrgBranding = () => {
  removeLocal(ORG_BRANDING_CACHE_KEY);
};

export const getPrivateModelConfig = async (): Promise<PrivateModelSettings> => {
  const response = await get(
    `/orgs/${AuthProvider.orgId}/private-models/user/${AuthProvider.spUserId}`
  );

  const { defaultPrivateModel, availablePrivateModels } = (response || {}) as {
    defaultPrivateModel: JsonObject | null;
    availablePrivateModels: JsonObject[];
  };

  return {
    defaultPrivateModelId: (defaultPrivateModel?.id as string) || '',
    availablePrivateModels: (availablePrivateModels || []).map(
      (data) => new PrivateModelModel(data)
    ),
  };
};
