import { AgentModel } from '@/lib/models/agent.model';
import { DatasourceModel } from '@/lib/models/datasource.model';
import { createContext } from 'react';

export interface IUserSettings {
  usePublicInternetDatasource: boolean;
  enableDatasource: (datasourceId: string | string[]) => void;
  disableDatasource: (datasourceId: string | string[]) => void;
  enablePublicSearch: () => void;
  disablePublicSearch: () => void;
  datasources: Map<string, Datasource>;
  agents: AgentModel[];
  isLoading: boolean;
}

export const UserSettingsContext = createContext<IUserSettings>({
  usePublicInternetDatasource: false,
  enableDatasource: () => null,
  disableDatasource: () => null,
  enablePublicSearch: () => null,
  disablePublicSearch: () => null,
  datasources: new Map(),
  agents: [],
  isLoading: false,
});

export type Datasource = {
  model: DatasourceModel;
  enabled: boolean;
};
