import { FC, useState } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Avatar, Box, SvgIconTypeMap, Theme } from '@mui/material';

import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import PublicIcon from '@mui/icons-material/Public';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PolicyIcon from '@mui/icons-material/Policy';
import TuneIcon from '@mui/icons-material/Tune';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import DataObjectIcon from '@mui/icons-material/DataObject';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PasswordIcon from '@mui/icons-material/Password';
import GoogleIcon from '@mui/icons-material/Google';
import MailLockIcon from '@mui/icons-material/MailLock';
import PersonIcon from '@mui/icons-material/Person';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SendIcon from '@mui/icons-material/Send';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DoneIcon from '@mui/icons-material/Done';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorIcon from '@mui/icons-material/Error';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PendingIcon from '@mui/icons-material/Pending';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import SubjectIcon from '@mui/icons-material/Subject';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AssistantIcon from '@mui/icons-material/Assistant';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useStyles = createUseStyles((theme: Theme) => ({
  icon: {
    '&.button': {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.link.main,
      },
    },
    '&.white .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
    '&.black .MuiSvgIcon-root': {
      color: theme.palette.common.black,
    },
    '&.grey .MuiSvgIcon-root': {
      color: theme.palette.grey[400],
    },
    '&.active .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    '&.success .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    },
    '&.error .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
    '&.link .MuiSvgIcon-root': {
      color: theme.palette.link.main,
    },
    '&.warning .MuiSvgIcon-root': {
      color: theme.palette.warning.main,
    },
    '&.disabled': {
      cursor: 'default',
      opacity: 0.2,
    },
  },
  iconBackground: {
    backgroundColor: theme.palette.secondary.light,
    '&.second': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&.small': {
      width: 28,
      height: 28,
    },
  },
  iconWrapper: {
    display: 'inline-block',
    lineHeight: '70%',
  },
  spin: {
    animation: '$spin 400ms linear',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export type IconColor =
  | 'white'
  | 'black'
  | 'grey'
  | 'active'
  | 'success'
  | 'error'
  | 'link'
  | 'warning';

export type IconType =
  | 'people'
  | 'person'
  | 'bar-chart'
  | 'layers'
  | 'chevron-left'
  | 'chevron-right'
  | 'menu'
  | 'notifs'
  | 'lock'
  | 'log-out'
  | 'chat'
  | 'globe'
  | 'enterprise'
  | 'shield-search'
  | 'tune'
  | 'article'
  | 'close'
  | 'search'
  | 'menu-arrow'
  | 'refresh'
  | 'plus-circle'
  | 'plus'
  | 'json'
  | 'info-circle'
  | 'arrow-right'
  | 'arrow-left'
  | 'arrow-circle-up'
  | 'text-file'
  | 'edit'
  | 'google'
  | 'password'
  | 'email-lock'
  | 'delete'
  | 'copy'
  | 'save'
  | 'cancel'
  | 'group'
  | 'lock-person'
  | 'send'
  | 'upload-file'
  | 'done'
  | 'history'
  | 'task'
  | 'network-error'
  | 'error'
  | 'cloud-upload'
  | 'pending'
  | 'build-circle'
  | 'shortcut'
  | 'deny'
  | 'link'
  | 'open-tab'
  | 'contact-support'
  | 'stop-circle'
  | 'magic'
  | 'doc-search'
  | 'settings'
  | 'subject'
  | 'manage-search'
  | 'double-down-arrow'
  | 'arrow-circle-right'
  | 'check-circle'
  | 'expand-less'
  | 'expand-more'
  | 'assistant';

type IconElementType = OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
  muiName: string;
};

const IconMap: Map<IconType, IconElementType> = new Map();
IconMap.set('people', PeopleIcon);
IconMap.set('person', PersonIcon);
IconMap.set('bar-chart', BarChartIcon);
IconMap.set('layers', LayersIcon);
IconMap.set('chevron-left', ChevronLeftIcon);
IconMap.set('chevron-right', ChevronRightIcon);
IconMap.set('menu', MenuIcon);
IconMap.set('notifs', NotificationsIcon);
IconMap.set('lock', LockOutlinedIcon);
IconMap.set('log-out', LogoutOutlined);
IconMap.set('chat', ForumIcon);
IconMap.set('globe', PublicIcon);
IconMap.set('enterprise', CorporateFareIcon);
IconMap.set('shield-search', PolicyIcon);
IconMap.set('tune', TuneIcon);
IconMap.set('article', ArticleIcon);
IconMap.set('close', CloseIcon);
IconMap.set('search', SearchIcon);
IconMap.set('menu-arrow', ArrowDropDownIcon);
IconMap.set('refresh', RefreshIcon);
IconMap.set('plus-circle', AddCircleIcon);
IconMap.set('plus', AddIcon);
IconMap.set('json', DataObjectIcon);
IconMap.set('info-circle', InfoIcon);
IconMap.set('arrow-right', ArrowForwardIcon);
IconMap.set('arrow-left', ArrowBackIcon);
IconMap.set('arrow-circle-up', ArrowCircleUpIcon);
IconMap.set('text-file', TextSnippetIcon);
IconMap.set('edit', EditIcon);
IconMap.set('google', GoogleIcon);
IconMap.set('password', PasswordIcon);
IconMap.set('email-lock', MailLockIcon);
IconMap.set('delete', RemoveCircleIcon);
IconMap.set('copy', ContentCopyIcon);
IconMap.set('save', SaveIcon);
IconMap.set('cancel', CancelIcon);
IconMap.set('group', GroupWorkIcon);
IconMap.set('lock-person', LockPersonIcon);
IconMap.set('send', SendIcon);
IconMap.set('upload-file', UploadFileIcon);
IconMap.set('done', DoneIcon);
IconMap.set('history', HistoryIcon);
IconMap.set('task', AssignmentIcon);
IconMap.set('shortcut', DynamicFormIcon);
IconMap.set('network-error', WarningAmberIcon);
IconMap.set('error', ErrorIcon);
IconMap.set('cloud-upload', CloudUploadIcon);
IconMap.set('pending', PendingIcon);
IconMap.set('build-circle', BuildCircleIcon);
IconMap.set('deny', DoNotDisturbIcon);
IconMap.set('link', LinkIcon);
IconMap.set('open-tab', OpenInNewIcon);
IconMap.set('contact-support', ContactSupportIcon);
IconMap.set('stop-circle', StopCircleIcon);
IconMap.set('magic', AutoAwesomeIcon);
IconMap.set('doc-search', DocumentScannerIcon);
IconMap.set('settings', SettingsIcon);
IconMap.set('subject', SubjectIcon);
IconMap.set('manage-search', ManageSearchIcon);
IconMap.set('double-down-arrow', KeyboardDoubleArrowDownIcon);
IconMap.set('arrow-circle-right', ArrowCircleRightIcon);
IconMap.set('check-circle', CheckCircleIcon);
IconMap.set('expand-less', ExpandLess);
IconMap.set('expand-more', ExpandMore);
IconMap.set('assistant', AssistantIcon);

export interface Props {
  name: IconType;
  onClick?: () => void;
  spinOnClick?: boolean;
  background?: 'first' | 'second';
  className?: string;
  color?: IconColor;
  size?: 'medium' | 'small' | 'large' | 'x-large';
  disabled?: boolean;
}

type IconFontSizeLabel = 'small' | 'medium' | 'large';

const getSizeProp = (
  size: string
): { fontSize: IconFontSizeLabel } | { sx: { fontSize: number } } => {
  if (['small', 'medium', 'large'].includes(size)) {
    return { fontSize: size as IconFontSizeLabel };
  }

  switch (size) {
    case 'x-large':
      return { sx: { fontSize: 80 } };
  }

  return { fontSize: 'medium' };
};

const Icon: FC<Props> = ({
  color = '',
  name,
  onClick,
  spinOnClick = false,
  background,
  className = '',
  size = 'medium',
  disabled = false,
}) => {
  const styles = useStyles();
  const Icon = IconMap.get(name);
  const [spin, setSpin] = useState(false);

  if (!Icon) {
    console.warn(`unknown icon type ${name}`);
    return null;
  }

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick && onClick();
    spinOnClick && setSpin(true);
  };

  const handleStopSpin = () => {
    setSpin(false);
  };

  const canClick = Boolean(onClick);
  const role = canClick ? 'button' : 'presentation';

  const IconEl = <Icon role={role} onClick={handleClick} {...getSizeProp(size)} />;

  if (background) {
    const avatarClass = classNames(
      styles.icon,
      styles.iconBackground,
      className,
      background,
      color,
      size,
      canClick && 'button'
    );
    return <Avatar className={avatarClass}>{IconEl}</Avatar>;
  }

  const boxClass = classNames(
    styles.icon,
    styles.iconWrapper,
    spin && styles.spin,
    className,
    color,
    { disabled, button: canClick }
  );
  return (
    <Box onAnimationEnd={handleStopSpin} className={boxClass}>
      {IconEl}
    </Box>
  );
};

export default Icon;
