import { Stack, Theme } from '@mui/material';
import Icon, { IconType } from '../icon';
import Text from '../text';
import { createUseStyles } from 'react-jss';
import { FC } from 'react';

interface Props {
  icon: IconType;
  title: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  tabHeader: {
    padding: '0px 0px 10px 0px',
    margin: '0px 16px 10px 18px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const TabHeader: FC<Props> = ({ icon, title }) => {
  const styles = useStyles();

  return (
    <Stack direction="row" gap={1} alignItems="center" className={styles.tabHeader}>
      <Icon name={icon} />
      <Text size="large">{title}</Text>
    </Stack>
  );
};

export default TabHeader;
