import { ReactNode, useCallback, useState } from 'react';
import { MESSAGE_TRAY_ID, MessageTrayContext } from './helpers';

export function MessageTrayProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [openIds, setOpenIds] = useState<MESSAGE_TRAY_ID[]>([]);

  const open = useCallback((trayId: MESSAGE_TRAY_ID) => {
    setOpenIds((old) => {
      if (old.includes(trayId)) {
        return old;
      }

      // only allow one tray to be open at a time, for now
      //return [...old, trayId];
      return [trayId];
    });
  }, []);

  const isOpen = (trayId: MESSAGE_TRAY_ID) => {
    return openIds.includes(trayId);
  };

  const close = useCallback((closeId: MESSAGE_TRAY_ID) => {
    setOpenIds((old) => {
      const closeIndex = old.findIndex((id) => id === closeId);
      if (closeIndex === -1) {
        return old;
      }
      const updated = [...old];
      updated.splice(closeIndex, 1);
      return updated;
    });
  }, []);

  const toggle = useCallback(
    (trayId: MESSAGE_TRAY_ID) => {
      if (openIds.includes(trayId)) {
        close(trayId);
        return;
      }
      open(trayId);
    },
    [openIds, close, open]
  );

  const closeAll = useCallback(() => {
    setOpenIds([]);
  }, []);

  return (
    <MessageTrayContext.Provider
      value={{
        openIds,
        isOpen,
        open,
        close,
        toggle,
        closeAll,
      }}
    >
      {children}
    </MessageTrayContext.Provider>
  );
}
