import { FC } from 'react';
import { AgentModel } from '@/lib/models/agent.model';
import { Avatar, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getFirstLastName } from '@/lib/helpers';

interface Props {
  agent?: AgentModel;
}

const useStyles = createUseStyles((theme: Theme) => ({
  agentBadge: {
    backgroundColor: '#7ebd94',
  },
  unavailableAgentBadge: {
    backgroundColor: theme.palette.grey[400],
  },
}));

const AgentBadge: FC<Props> = ({ agent }) => {
  const styles = useStyles();

  if (!agent) {
    return <Avatar className={styles.unavailableAgentBadge}></Avatar>;
  }

  const { image, title } = agent.portal.badge;

  if (image) {
    return <img src={image} alt={title} width={40} />;
  }

  const { first, last } = getFirstLastName(title);
  const initials = `${first[0] || ''}${last[0] || ''}`.toUpperCase();

  return <Avatar className={styles.agentBadge}>{initials}</Avatar>;
};

export default AgentBadge;
