import { QueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { TOAST_CONFIG } from '../hooks/use-toast.hook';
import { addError } from './datadog';
import { AxiosError } from 'axios';

export enum QueryKey {
  ThreadsDataset = 'threads-dataset',
  ShortcutsList = 'shortcuts-list',
  DatasourceList = 'datasource-list',
  PrivateModelsList = 'private-models-list',
  AgentsList = 'agents-list',
}

enum QueryErrorText {
  Application = 'There was an application error',
  Network = 'There was a network error',
  Service = 'There was a service error',
}

const handleQueryError = (error: AxiosError) => {
  let errorText = '';

  try {
    const { status, code } = error;

    switch (status) {
      case 401:
        errorText = 'Authentication failed. Please reload the page.';
        break;
      case 403:
        errorText = 'Authorization failed';
        break;
    }

    if (!errorText) {
      switch (code) {
        case 'ERR_BAD_REQUEST':
          errorText = QueryErrorText.Service;
          break;
        case 'EINVAL':
          errorText = QueryErrorText.Application;
          break;
        case 'ECONNABORTED':
        case 'EADDRINFO':
        case 'ENOTFOUND':
        case 'EAI_AGAIN':
        case 'ECONNREFUSED':
        case 'EHOSTDOWN':
        case 'EHOSTUNREACH':
          errorText = QueryErrorText.Network;
          break;
        case 'ETIMEDOUT':
        case 'ESOCKETTIMEDOUT':
          if (!error.request) {
            errorText = QueryErrorText.Network;
          }
          break;
      }
    }
  } catch {
    console.error('unexpected error schema', error);
  }

  console.log(error);

  addError(error);

  enqueueSnackbar(errorText || QueryErrorText.Service, { ...TOAST_CONFIG, variant: 'error' });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 4000, // how long is data considered fresh for?
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
      onError: handleQueryError,
    },
  },
});

export default queryClient;
