import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

type AgentPortalConfig = {
  description: string;
  greeting: string;
  badge: {
    title: string;
    image: string;
  };
};

export class AgentModel extends BaseModel {
  orgId: string;
  name: string;
  description: string;
  portal: AgentPortalConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof AgentModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';
    this.portal = (data.portal as AgentPortalConfig) || {
      description: '',
      greeting: '',
      badge: {
        title: '',
        image: '',
      },
    };
  }
}
