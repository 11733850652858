import { FC, useRef } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import Page, { PageProps } from '../../components/page';
import { Box, Stack, Theme } from '@mui/material';
import AppLayout from '@/components/layouts/app';
import ThreadList from './thread-list';
import { createUseStyles } from 'react-jss';
import MessageControl, { MessageControlInstance } from './message-control';
import ThreadOverlay from './thread-overlay';
import WelcomeMessage from './welcome-message';
import FileDropzone from '@/components/file-dropzone';
import FollowButton from './follow-button';
import classNames from 'classnames';
import { MessageTrayProvider } from '@/contexts/message-tray';

const useStyles = createUseStyles((theme: Theme) => ({
  threadPanel: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  scrollContext: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    paddingTop: 10,
  },
  scrollView: {
    scrollBehavior: 'auto',
  },
  contentContainer: {
    width: '100%',
    maxWidth: 1200,
    padding: '0px 40px',
    boxSizing: 'border-box',
    margin: '0 auto',
  },
  messageControlContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '10px 0px 20px 0px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  messageControlToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  defaultFollowButton: {
    display: 'none',
  },
}));

const HomePage: FC<PageProps> = () => {
  const styles = useStyles();

  const messageControlRef = useRef<MessageControlInstance>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseWelcomeMessage = () => {
    messageControlRef.current?.focus();
  };

  const handleDrop = (files: FileList) => {
    messageControlRef.current?.loadFiles(files);
  };

  return (
    <AppLayout>
      <Page title="Home">
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          width="100%"
          height="100%"
          position="relative"
        >
          <FileDropzone className={styles.threadPanel} onDrop={handleDrop}>
            <ScrollToBottom
              className={styles.scrollContext}
              initialScrollBehavior="smooth"
              scrollViewClassName={styles.scrollView}
              followButtonClassName={styles.defaultFollowButton}
            >
              <Box className={styles.contentContainer} minHeight="100%">
                <ThreadList messageControlRef={messageControlRef} />
              </Box>
              <FollowButton />
            </ScrollToBottom>
            <ThreadOverlay />
          </FileDropzone>
          <Box className={styles.messageControlContainer}>
            <Box
              className={classNames(styles.contentContainer, styles.messageControlToolbar)}
              ref={containerRef}
            ></Box>
            <Box className={styles.contentContainer}>
              <MessageTrayProvider>
                <MessageControl ref={messageControlRef} />
              </MessageTrayProvider>
            </Box>
          </Box>
          <WelcomeMessage onClose={handleCloseWelcomeMessage} />
        </Stack>
      </Page>
    </AppLayout>
  );
};

export default HomePage;
