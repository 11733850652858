import { Stack } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import Text from '../text';
import { useQuery } from 'react-query';
import TextInput from '../form/text-input';
import useThread from '@/hooks/use-thread.hook';
import usePrior from '@/hooks/use-prior.hook';
import { Thread, getThreads, sortThreads } from '@/lib/services/thread.service';
import { cloneDeep } from 'lodash';
import { getNiceRecentDate } from '@/lib/date-helpers';
import SidebarList from './sidebar-list';
import { QueryKey } from '@/lib/query-client';
import useThreadToast from '@/hooks/use-thread-toast.hook';
import TabHeader from './tab-header';
import TabContent from './tab-content';

const searchThreads = (threads: Thread[], keyword?: string) => {
  if (!threads.length) {
    return [];
  }

  const safeKeyword = String(keyword).trim().toLowerCase();

  if (!safeKeyword) {
    return threads;
  }

  const fThreads = safeKeyword
    ? threads.filter(({ messages }) =>
        messages.some(({ content }) => String(content).toLowerCase().includes(safeKeyword))
      )
    : threads;

  return fThreads;
};

const HistoryTab: FC = () => {
  const [keyword, setKeyword] = useState('');
  const [threads, setThreads] = useState<Thread[]>([]);

  const { loadThread, thread: activeThread } = useThread();
  const { clear } = useThreadToast();

  const { data, isLoading } = useQuery([QueryKey.ThreadsDataset], async () => getThreads());

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const handleLoadThread = (threadId: string) => {
    loadThread(threadId).then((loaded) => {
      if (loaded) {
        clear();
      }
    });
  };

  useEffect(() => {
    if (data) {
      setThreads(data.rows);
    }
  }, [data]);

  // update last message of active thread
  const priorAppMessages = usePrior(activeThread.messages);
  useEffect(() => {
    if (priorAppMessages && priorAppMessages.length < activeThread.messages.length) {
      setThreads((old) => {
        const updatedThreads = cloneDeep(old);
        const existIndex = updatedThreads.findIndex(({ id }) => id === activeThread.id);
        const listedActiveThread = cloneDeep(activeThread);

        if (existIndex > -1) {
          updatedThreads[existIndex] = listedActiveThread;
        } else {
          updatedThreads.unshift(listedActiveThread);
        }
        return updatedThreads;
      });
    }
  }, [priorAppMessages, activeThread]);

  // @todo memoize this
  const fThreads = isLoading ? [] : searchThreads(threads, keyword);
  const threadListKey = fThreads.map(({ id }) => id).join('|'); // otherwise react doesn't pick up when a new thread is added

  const listThreads = fThreads.filter(({ messages }) => messages?.length);
  sortThreads(listThreads);

  const listItems = listThreads.map(({ messages }) => {
    const userMessages = messages.filter(({ role }) => role === 'user');
    const lastMessage = userMessages[userMessages.length - 1];
    const { threadId, createdAt, content, shortcut, context } = lastMessage || {};
    const hasShortcut = !!shortcut;
    const isFile = context?.type === 'file';

    let blurb: ReactNode = content;
    if (hasShortcut) {
      blurb = `Shortcut: ${shortcut.name}`;
    } else if (isFile) {
      blurb = <>File Upload: {context?.name || <Text italic>not found</Text>}</>;
    }

    return {
      title: getNiceRecentDate(createdAt),
      content: (
        <Text size="small" dotdot>
          {blurb}
        </Text>
      ),
      key: `message-${threadListKey}-${lastMessage.id}`,
      onClick: () => handleLoadThread(threadId),
      active: activeThread.id === threadId,
    };
  });

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%">
      <TabHeader icon="history" title="History" />
      <TabContent>
        <TextInput
          name="history-search"
          value={keyword}
          onChange={handleKeywordChange}
          size="small"
          startIcon="search"
          placeholder="Search history"
          clearable
        />
      </TabContent>
      <SidebarList isLoading={isLoading} items={listItems} />
    </Stack>
  );
};

export default HistoryTab;
