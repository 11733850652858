import AgentBadge from '@/components/agent-badge';
import Text from '@/components/text';
import { AgentModel } from '@/lib/models/agent.model';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  agent: AgentModel;
  onClick: (agentId: string) => void;
  selected?: boolean;
  height: number;
}

const useStyles = createUseStyles<string, { height: number }>({
  agentOpt: {
    padding: 8,
    boxSizing: 'border-box',
    height: ({ height }) => height,
    overflow: 'hidden',
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: '#ddeeff',
    },
  },
});

const AgentOpt: FC<Props> = ({ agent, onClick, selected = false, height }) => {
  const {
    id,
    portal: { badge },
  } = agent;
  const styles = useStyles({ height });

  const handleLoadAgent = (agentId: string) => {
    onClick(agentId);
  };

  const compClass = classNames(styles.agentOpt, 'agent-opt', { selected });

  return (
    <Stack
      width="100%"
      direction="row"
      gap={2}
      alignItems="center"
      justifyContent="flex-start"
      className={compClass}
      onClick={() => handleLoadAgent(id)}
    >
      <Stack justifyContent="center">
        <AgentBadge agent={agent} />
      </Stack>
      <Box flexGrow={1} maxWidth="calc(100% - 100px)" maxHeight="100%">
        <Text bold>{badge?.title || 'Assistant'}</Text>
      </Box>
    </Stack>
  );
};

export default AgentOpt;
