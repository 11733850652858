import { ReactNode, RefObject, forwardRef } from 'react';
import { Box, Portal, Slide, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import useMessageTray from '@/hooks/use-message-tray.hook';
import { MESSAGE_TRAY_ID } from '@/contexts/message-tray/helpers';

interface Props {
  height: number;
  containerRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  className?: string;
  trayId: MESSAGE_TRAY_ID;
}

const TRAY_DURATION_MS = 250;

const useStyles = createUseStyles<string, { open: boolean; trayHeight: number }>(
  (theme: Theme) => ({
    trayContainer: {
      position: 'absolute',
      top: ({ trayHeight }) => trayHeight * -1,
      left: 0,
      width: '100%',
      zIndex: 1,
      height: ({ open, trayHeight }) => (open ? trayHeight : 0),
      transition: ({ open }) =>
        open ? '' : `height ${TRAY_DURATION_MS}ms ease-out ${TRAY_DURATION_MS + 20}ms`,
      overflow: 'hidden',
      '& .inner': {
        position: 'relative',
        width: '100%',
        height: '100%',
      },
    },
    traySlider: {
      position: 'absolute',
      height: ({ trayHeight }) => trayHeight,
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
    },
    trayInner: {
      height: '100%',
      width: '100%',
    },
  })
);

const MessageTray = forwardRef<HTMLDivElement, Props>(
  ({ containerRef, height, children, className, trayId }, ref) => {
    const { isOpen } = useMessageTray();
    const open = isOpen(trayId);

    const styles = useStyles({ open, trayHeight: height });

    const compClass = classNames(styles.trayContainer, className);

    return (
      <Box ref={ref}>
        <Portal container={containerRef.current}>
          <Box className={compClass}>
            <Box className="inner">
              <Slide
                direction="up"
                in={open}
                mountOnEnter
                unmountOnExit
                timeout={TRAY_DURATION_MS}
                className={styles.traySlider}
              >
                <Box className={styles.trayInner}>{children}</Box>
              </Slide>
            </Box>
          </Box>
        </Portal>
      </Box>
    );
  }
);

export default MessageTray;
