import { Box } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles({
  tabContent: {
    margin: '0px 16px 0px 18px',
  },
});

const TabContent: FC<Props> = ({ children }) => {
  const styles = useStyles();

  return <Box className={styles.tabContent}>{children}</Box>;
};

export default TabContent;
