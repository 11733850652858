import { createContext } from 'react';

export type MESSAGE_TRAY_ID = 'controls' | 'upload';

export interface MessageTraySettings {
  openIds: MESSAGE_TRAY_ID[];
  open: (id: MESSAGE_TRAY_ID) => void;
  isOpen: (id: MESSAGE_TRAY_ID) => boolean;
  close: (id: MESSAGE_TRAY_ID) => void;
  toggle: (id: MESSAGE_TRAY_ID) => void;
  closeAll: () => void;
}

export const MessageTrayContext = createContext<MessageTraySettings>({
  openIds: [],
  isOpen: () => false,
  open: () => null,
  close: () => null,
  toggle: () => null,
  closeAll: () => null,
});
