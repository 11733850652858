import { FC, RefObject } from 'react';
import { Box, Portal, Slide, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import InlineToast from '@/components/inline-toast';
import useThreadToast from '@/hooks/use-thread-toast.hook';

interface Props {
  containerRef: RefObject<HTMLDivElement>;
}

const TRAY_DURATION_MS = 250;

const TRAY_HEIGHT = 52;

const useStyles = createUseStyles<string, { open: boolean }>((theme: Theme) => ({
  threadToastTray: {
    position: 'absolute',
    top: TRAY_HEIGHT * -1,
    left: 0,
    width: '100%',
    zIndex: 10,
    height: ({ open }) => (open ? TRAY_HEIGHT : 0),
    transition: ({ open }) =>
      open ? '' : `height ${TRAY_DURATION_MS}ms ease-out ${TRAY_DURATION_MS + 20}ms`,
    overflow: 'hidden',
    '& .inner': {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  threadToastSlider: {
    position: 'absolute',
    height: TRAY_HEIGHT,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: theme.palette.common.white,
  },
}));

const ThreadToastTray: FC<Props> = ({ containerRef }) => {
  const { message, open } = useThreadToast();
  const styles = useStyles({ open });

  const { content, variant } = message || {};
  const hasMessage = !!content && !!variant;
  const alertColor = variant === 'default' ? 'info' : variant;

  return (
    <Box>
      <Portal container={containerRef.current}>
        <Box className={styles.threadToastTray}>
          <Box className="inner">
            <Slide
              direction="up"
              in={open}
              mountOnEnter
              unmountOnExit
              timeout={TRAY_DURATION_MS}
              className={styles.threadToastSlider}
            >
              <Box height="100%" width="100%">
                <Box height="100%" width="100%">
                  {hasMessage && <InlineToast message={content} level={alertColor} />}
                </Box>
              </Box>
            </Slide>
          </Box>
        </Box>
      </Portal>
    </Box>
  );
};

export default ThreadToastTray;
