import Loader from '@/components/loader';
import useApp from '@/hooks/use-app.hook';
import useThread from '@/hooks/use-thread.hook';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { getDefaultOrgAgent, ORG_DEFAULT_AGENT_ID } from '@/lib/services/shortcut.service';
import { Box, ClickAwayListener, Collapse, Fade, Stack, Theme } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import AgentOpt from './agent-opt';
import Icon from '@/components/icon';
import TextInput, { TextInputInterface } from '@/components/form/text-input';
import Text from '@/components/text';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useHotkeys } from 'react-hotkeys-hook';

interface Props {
  onChangeOpen?: (open: boolean) => void;
}

const AGENT_OPT_HEIGHT = 60;
const COLLAPSE_TIMEOUT = 200;

const useStyles = createUseStyles((theme: Theme) => ({
  agentSelector: {
    width: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
  },
  agentList: {
    maxHeight: AGENT_OPT_HEIGHT * 5,
  },
  agentSelected: {
    height: AGENT_OPT_HEIGHT,
    padding: '0px 8px 0px 0px',
  },
  agentSelectInput: {
    height: AGENT_OPT_HEIGHT,
    padding: 8,
    '& fieldset': {
      border: `1px solid ${theme.palette.grey[200]} !important`,
    },
  },
  agentOptList: {
    '& .agent-opt': {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

const AgentSelector: FC<Props> = ({ onChangeOpen }) => {
  const styles = useStyles();
  const { thread, setAgentId } = useThread();
  const { agents, isLoading: showLoader } = useUserSettings();
  const { branding } = useApp();
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef<TextInputInterface>(null);

  const { agentId } = thread;

  const handleClickAgentOpt = (agentId: string) => {
    const safeAgentId = agentId === ORG_DEFAULT_AGENT_ID ? '' : agentId;
    setAgentId(safeAgentId);
    setOpen(false);
  };

  const handleToggleOpen = () => {
    setOpen(!open);
    if (!open) {
      // @todo why doesn't this work? :-/
      inputRef.current?.focus();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeywordChange = (val: string) => {
    setKeyword(val);
  };

  // append the default org portal branding as the default agent to the list of available agents
  const orgAgents = useMemo(() => {
    return [getDefaultOrgAgent(branding), ...agents];
  }, [branding, agents]);

  const fOrgAgents = useMemo(() => {
    const safeKeyword = String(keyword).trim().toLowerCase();
    return orgAgents.filter(({ portal }) => {
      const { description, greeting, badge } = portal;
      return [description, greeting, badge?.title]
        .filter((v) => v)
        .map((v) => String(v).toLowerCase())
        .some((v) => v.includes(safeKeyword));
    });
  }, [orgAgents, keyword]);

  const selectedAgentId = agentId || ORG_DEFAULT_AGENT_ID;
  const hasMultipleAgents = orgAgents.length > 1;

  useHotkeys('esc', handleClose);

  useEffect(() => {
    onChangeOpen?.(open);
    if (!open) {
      setKeyword('');
    }
  }, [open, onChangeOpen]);

  if (showLoader) {
    return <Loader />;
  }

  if (!hasMultipleAgents) {
    return null;
  }

  const selectedAgent = orgAgents.find(({ id }) => id === selectedAgentId) || orgAgents[0];
  const noSearchMatch = !!keyword && !fOrgAgents.length;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box width="100%">
        <Stack className={styles.agentSelector}>
          <Stack direction="row" width="100%" className={styles.agentSelected} alignItems="center">
            <Box flexGrow={1}>
              {open && (
                <Box className={styles.agentSelectInput}>
                  <TextInput
                    name="agent-select-keyword"
                    value={keyword}
                    onChange={handleKeywordChange}
                    placeholder="Search by keyword"
                    size="small"
                    ref={inputRef}
                    clearable
                  />
                </Box>
              )}
              {!open && (
                <AgentOpt
                  agent={selectedAgent}
                  onClick={handleToggleOpen}
                  height={AGENT_OPT_HEIGHT}
                />
              )}
            </Box>

            <Icon name={open ? 'expand-less' : 'expand-more'} onClick={handleToggleOpen} />
          </Stack>
          <Collapse in={open} timeout={COLLAPSE_TIMEOUT} unmountOnExit className={styles.agentList}>
            <Scrollbars autoHeight autoHeightMax={AGENT_OPT_HEIGHT * 4}>
              {noSearchMatch && (
                <Box p={1}>
                  <Text italic>No search results</Text>
                </Box>
              )}
              <Box className={styles.agentOptList}>
                {fOrgAgents.map((agent) => {
                  const selected = agent.id === selectedAgentId;
                  return (
                    <AgentOpt
                      agent={agent}
                      key={agent.id}
                      onClick={handleClickAgentOpt}
                      selected={selected}
                      height={AGENT_OPT_HEIGHT}
                    />
                  );
                })}
              </Box>
            </Scrollbars>
          </Collapse>
        </Stack>

        <Box mt={4}>
          <Fade in={!open} mountOnEnter unmountOnExit timeout={COLLAPSE_TIMEOUT + 10}>
            <Stack width="100%" alignItems="center">
              <Text>
                {selectedAgent?.portal.greeting || 'What would you like to get done today?'}
              </Text>
            </Stack>
          </Fade>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default AgentSelector;
